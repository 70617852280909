import React from "react"
import { stripString } from "../../utils";

const Section = ({sectionBackgroundColor, children, anchorId, className, index}) => {
  return (
    <section 
      key={index} 
      className={className} 
      id={anchorId ? stripString(anchorId) : ''} 
      style={{backgroundColor: sectionBackgroundColor}}
      
    >
     {children}
    </section>   
  );
}

export default Section;


