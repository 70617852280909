import React, {useState, useEffect} from "react";
import SVG from 'react-inlinesvg';

import Layout from "../components/wrappers/layout";
import SiteHeaderAlt from "../components/wrappers/site-header-alt";
import SEO from "../components/seo";
import Header from '../components/header';
import { graphql } from 'gatsby';
import Markdown from 'markdown-to-jsx'
import { getRandomIndex, stripString } from "../utils";
import Link from "../components/link";
import TabbedServices from "../components/tabbed-services";
import { getSinglePage } from "../utils";
import Testimonial from "../components/testimonial";
import { useContext } from "react";
import { GlobalStateContext } from "../context/GlobalContextProvider";
import Section from "../components/wrappers/section";
import { CustomHr } from "../components/CustomHr";
import classNames from "classnames";

const About = props => {
  const { general, footers, headers, aboutUs} = props.data.strapi; 
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode, clientLogosColorDarkMode, clientLogosColorLightMode, fontColorDarkMode, fontColorLightMode} = general; 
  const { testimonials, clientlogos, copyright, darkTheme } = aboutUs; 
  const language = props.pageContext.language;
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  //getting page first H1 element and setting this to meta  title and description if seo field in strapi not specified . ; 
  const pageFirstHeading = aboutUs.intro.header && (aboutUs.intro.header.supheading + " " + aboutUs.intro.header.heading); 

  const seoTitle = aboutUs.seo && aboutUs.seo.metaData.metaTitle || pageFirstHeading|| "About us";
  const seoDescription = aboutUs.seo && aboutUs.seo.metaData.metaDescription || pageFirstHeading ||"About us";
  const seoKeywords = aboutUs.seo && aboutUs.seo?.metaData?.keywords; 
  const allPages = props.data?.allSitePage?.nodes; 
  const webpages = aboutUs && aboutUs.webpages && aboutUs.webpages || {}; 
  const redirectTo = getSinglePage(webpages, allPages); 
  const newAnchorLinks = []; 
  const [anchorLinks, setAnchorLinks] = React.useState([]);
  const imageBorderRadiusForServices = general?.imageBorderRadiusForServices;
  const [displayedLogos, setDisplayedLogos] = useState(clientlogos.slice(0, 8));
  const [availableLogos, setAvailableLogos] = useState(clientlogos.slice(8));

  const [availableCollages, setAvailableCollages] = useState(aboutUs.intro.collage);
  const [displayedCollages, setDisplayedCollages] = useState(availableCollages.slice(0, 8));
  const [replacedActiveLogoIndex, setLogoReplacedIndex] = useState(undefined);


  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon'; 
  const primaryBtn = general?.primaryBtn;
  const greySectionLightModeBgColor = general?.greySectionLightModeBgColor; 
  const greySectionDarkModeBgColor = general?.greySectionDarkModeBgColor; 
  const greySectionBgColor = darkMode ? greySectionDarkModeBgColor : greySectionLightModeBgColor; 
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const logosBgColor = darkMode ? clientLogosColorDarkMode : clientLogosColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode;
  
  let images; 
  if (typeof window !== 'undefined') {
    images =  aboutUs.intro.collage && aboutUs.intro.collage.map(img => window.location.origin + img.url); 
  }
  if (copyright) {
    footer.copyright = copyright;
  }
  useEffect(() => {
    const swapLogo = () => {
      if (availableLogos.length > 0) {
        const randomIndex = Math.floor(Math.random() * availableLogos.length);
        const randomLogo = availableLogos[randomIndex];

        const replaceIndex = Math.floor(Math.random() * displayedLogos.length);

        const newDisplayedLogos = [...displayedLogos];
        const [replacedLogo] = newDisplayedLogos.splice(replaceIndex, 1, randomLogo);
        setLogoReplacedIndex(replaceIndex);
        setTimeout(() => {
          setDisplayedLogos(newDisplayedLogos);
          setAvailableLogos([...availableLogos, replacedLogo].filter(logo => logo !== randomLogo));
          setLogoReplacedIndex(undefined);
        }, 250); // Adjust timing as needed
      }
    };

    const intervalId = setInterval(swapLogo, 3000); // Swap every 3 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [displayedLogos, availableLogos]);

  useEffect(() => {
    let timeoutId;

    const swapCollage = () => {
      if (availableCollages.length > 0) {
        const updatedCollages = [...displayedCollages];
        
        const unusedCollages = availableCollages.filter(availableCollage => 
          !updatedCollages.some(displayedCollage => displayedCollage.url === availableCollage.url)
        );

        if (unusedCollages.length > 0) {
          const nextIndex = Math.floor(Math.random() * unusedCollages.length);
          const nextCollage = unusedCollages[nextIndex];
          const replaceIndex = Math.floor(Math.random() * updatedCollages.length);

          const img = new Image();
          img.onload = () => {
            const newCollages = updatedCollages.map((collage, index) => 
              index === replaceIndex ? {...collage, animateOut: true} : collage
            );

            setDisplayedCollages(newCollages);

            setTimeout(() => {
              setDisplayedCollages(currentCollages => 
                currentCollages.map((collage, index) => 
                  index === replaceIndex ? nextCollage : collage
                )
              );
            }, 250);
              
            timeoutId = setTimeout(swapCollage, 3000);
          };
          img.src = nextCollage.url;
        }
      }
    };

    timeoutId = setTimeout(swapCollage, 3000);
    return () => clearTimeout(timeoutId);
  }, [displayedCollages, availableCollages]);

  React.useEffect(() => {
    aboutUs.intro?.header?.anchorText && newAnchorLinks.push({header: aboutUs.intro.header.anchorText, display: true}); 
    aboutUs.stats?.header?.anchorText && newAnchorLinks.push({header: aboutUs.stats?.header?.anchorText, display: true}); 
    aboutUs?.services?.header?.anchorText && newAnchorLinks.push({header: aboutUs.services.header.anchorText , display: true}); 
    aboutUs?.logos?.header.anchorText && newAnchorLinks.push({header: aboutUs.logos.header.anchorText, display: true}); 
    aboutUs?.testimonials.anchorText && newAnchorLinks.push({header: aboutUs.testimonials.anchorText, display: true}); 
    setAnchorLinks(newAnchorLinks);  
  }, []); 

    return (
    <Layout header={header} footer={footer} language={language} redirectTo={redirectTo} anchorLinks={anchorLinks} logosBgColor={logosBgColor}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="service-detail" 
        title={seoTitle} 
        description={seoDescription}  
        keywords={seoKeywords} 
        darkTheme={darkTheme} 
        darkMode={darkMode} 
        websiteBackgroundColor={sectionBackgroundColor}
      />
      <SiteHeaderAlt header={header} redirectTo={redirectTo} anchorLinks={anchorLinks}>
        {aboutUs && (
          <>
            {aboutUs.intro && (
              <Section 
                className="section section-small-margin" 
                id={aboutUs.intro.header.anchorText ? stripString(aboutUs.intro.header.anchorText ) : ''} 
                sectionBackgroundColor={sectionBackgroundColor}
              >
                {aboutUs.intro.header && (
                  <Header heading={aboutUs.intro.header.heading} supheading={aboutUs.intro.header.supheading} />
                )}
                <div className="container">
                  <div className="row">
                    <div className="offset-md-2 col-md-7">
                      <Markdown options={{
                        overrides: {a: {
                          component: Link
                        },                           
                        hr: { 
                            component: CustomHr,
                            props: {
                              borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                            }
                          }  
                        }
                      }}>
                        {aboutUs.intro.body}
                      </Markdown>
                    </div>
                  </div>
                </div>
                {displayedCollages && displayedCollages.length > 0 && (
                  <div className="collage">
                    <div className="container">
                      <div className="row collage__inner">
                        {displayedCollages[0] && (
                          <div className="offset-md-1 col-md-3 collage-item">
                            <img 
                              src={displayedCollages[0].url} 
                              style={{borderRadius: imageBorderRadiusForServices}}
                              alt={displayedCollages[0].alternativeText} 
                              loading="lazy"
                              className={displayedCollages[0].animateOut ? 'collage-item-outro' : ''}
                            />
                          </div>
                        )}
                        {displayedCollages[1] && (
                          <div className="offset-md-1 col-md-6 collage-item">
                            <img 
                              src={displayedCollages[1].url} 
                              style={{borderRadius: imageBorderRadiusForServices}}
                              alt={displayedCollages[1].alternativeText} 
                              loading="lazy"
                              className={displayedCollages[1].animateOut ? 'collage-item-outro' : ''}
                            />
                          </div>
                        )}
                        {displayedCollages[2] && (
                          <div className="offset-md-1 col-md-6 collage-item">
                            <img 
                              style={{borderRadius: imageBorderRadiusForServices}}
                              src={displayedCollages[2].url} 
                              alt={displayedCollages[2].alternativeText} 
                              loading="lazy"
                              className={displayedCollages[2].animateOut ? 'collage-item-outro' : ''}
                            />
                          </div>
                        )}
                        {displayedCollages[3] && (
                          <div className="offset-md-1 col-md-4 collage-item">
                            <div className="row">
                              <div className="col-md-9 collage-item">
                                <img 
                                  src={displayedCollages[3].url}
                                  style={{borderRadius: imageBorderRadiusForServices}} 
                                  alt={displayedCollages[3].alternativeText} 
                                  loading="lazy"
                                  className={displayedCollages[3].animateOut ? 'collage-item-outro' : ''}
                                />
                              </div>
                              {displayedCollages[4] && (
                                <div className="offset-md-3 col-md-9 collage-item">
                                  <img 
                                    src={displayedCollages[4].url}
                                    style={{borderRadius: imageBorderRadiusForServices}} 
                                    alt={displayedCollages[4].alternativeText} 
                                    loading="lazy"
                                    className={displayedCollages[4].animateOut ? 'collage-item-outro' : ''}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {displayedCollages[5] && (
                          <div className="col-md-4 collage-item">
                            <div className="row">
                              {displayedCollages[5] && (
                                <div className="offset-md-3 col-md-9 collage-item">
                                  <img 
                                    src={displayedCollages[5].url}
                                    style={{borderRadius: imageBorderRadiusForServices}} 
                                    alt={displayedCollages[5].alternativeText} 
                                    loading="lazy"
                                    className={displayedCollages[5].animateOut ? 'collage-item-outro' : ''}
                                  />
                                </div>
                              )}
                              {displayedCollages[6] && (
                                <div className="col-md-9 collage-item">
                                  <img 
                                    src={displayedCollages[6].url}
                                    style={{borderRadius: imageBorderRadiusForServices}} 
                                    alt={displayedCollages[6].alternativeText} 
                                    loading="lazy"
                                    className={displayedCollages[6].animateOut ? 'collage-item-outro' : ''}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {displayedCollages[7] && (
                          <div className="offset-md-1 col-md-6 collage-item">
                            <img 
                              src={displayedCollages[7].url}
                              style={{borderRadius: imageBorderRadiusForServices}} 
                              alt={displayedCollages[7].alternativeText} 
                              loading="lazy"
                              className={displayedCollages[7].animateOut ? 'collage-item-outro' : ''}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Section>
            )}
            {(aboutUs.testimonials && aboutUs.testimonials.testimonials.length > 0) && (
              <Section 
                className="section" 
                id={stripString(aboutUs.testimonials.anchorText || '')}
                sectionBackgroundColor={sectionBackgroundColor}
              >
                <div className="container">
                  <Testimonial 
                    testimonialBtnText={primaryBtn}
                    title={aboutUs.testimonials.title}
                    testimonials={aboutUs.testimonials.testimonials}  
                    isSticky={true}
                    imageBorderRadiusForServices={imageBorderRadiusForServices}
                    sectionBackgroundColor={sectionBackgroundColor}
                    logosBgColor={logosBgColor}
                  />
                </div>
              </Section>
            )} 
          
            {aboutUs.stats && (
              <section 
                className="section section--grey stats" 
                id={aboutUs.stats.header.anchorText ? stripString(aboutUs.stats.header.anchorText ) : ''}
                style={{backgroundColor: greySectionBgColor}}
              >
                {aboutUs.stats.header && (
                  <Header heading={aboutUs.stats.header.heading} supheading={aboutUs.stats.header.supheading} withFullWidth={true}/>
                )}
                
                {aboutUs.stats.stat && aboutUs.stats.stat.length > 0 && (
                  <div className="container">
                    <div className="row">
                      <div className="col-12 stats__list">
                        {aboutUs.stats.stat.map((stat) => {
                          return (
                            <div className="stats__stat">
                              <h2>{stat.value}</h2>
                              <h3 style={{color: websiteFontColor }}>{stat.label}</h3>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </section>
            )}
            {aboutUs.services && (
              <TabbedServices 
                component={aboutUs.services} 
                language={language} 
                imageBorderRadiusForServices={imageBorderRadiusForServices} 
                sectionBackgroundColor={sectionBackgroundColor}
                websiteFontColor={websiteFontColor}
              />
            )}
            {clientlogos && (
              <section 
                className="section section--grey services-logos overflow-hidden" 
                id={aboutUs.logos.header.anchorText ? stripString(aboutUs.logos.header.anchorText ) : ''} 
                style={{backgroundColor: greySectionBgColor}}
              >
                {aboutUs.logos.header && (
                  <Header heading={aboutUs.logos.header.heading} supheading={aboutUs.logos.header.supheading}/>
                )}
                
                {clientlogos.length > 0 && (
                  <div className="container">
                    <div className="row">
                      <div className="offset-md-1 col-md-10">
                        <ul className="services-logos__list row">
                          {displayedLogos.map((clientLogo, index) => {
                            const logo = clientLogo.logo;
                            return (
                              <li key={index} className={classNames("services-logos__item services-logos__item--spaced col-6 col-md-3")}  title={logo.alternativeText} style={logosBgColor === "color" ? {} : {fill: logosBgColor }}>
                                <SVG
                                  src={logo.url}
                                  className={classNames("services-logos__svg", {"services-logos__svg--rotate": index === replacedActiveLogoIndex })} // Apply animation class conditionally
                                />  
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </section>
            )}
          </>
        )}
      </SiteHeaderAlt>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: ID!, $language: String!)  {
    allSitePage {
      nodes {
        path
      }
    }
      strapi {
        general {
          primaryBtn
          blogsOverviewHeadline
          imageBorderRadiusForServices
          fontColorDarkMode
          fontColorLightMode
          greySectionLightModeBgColor
          greySectionDarkModeBgColor
          generalBackgroundColorDarkMode
          generalBackgroundColorLightMode
          clientLogosColorDarkMode
          clientLogosColorLightMode
          fontColorDarkMode
          fontColorLightMode
        }
        aboutUs(id: $id) {
          copyright
          darkTheme
          clientlogos {
            logo {
              url
              ext
              mime
              alternativeText
            }
          }
          seo {
            metaData {
              metaTitle
              metaDescription
              keywords
            }
          }
          language
          intro {
            header {
              supheading
              heading
              anchorText
            }
            body
            collage {
              url
              alternativeText
              mime
              ext
            }
          }
          webpages {
            about_us {
              language
            }
            contact {
              language
            }
            case_study {
              slug
              language
            }
            blogs_overview {
              language
            }
            cases_overview {
              language
            }
            careers_overview {
              language
            }	
            services_overview {
              language
            }
            blog {
              language
              slug
            }
            career {
              language
              slug
            }
            homepage {
              slug
              language
            }
            service_design {
              slug
              language
            }
            service_technical {
              slug
              language
            }
            service_casey_new {
              slug
              language
            }
            service_casey {
              slug
              language
            }
            plainpage {
              language
              slug
            }
          }
          stats {
            header {
              supheading
              heading
              anchorText
            }
            stat {
              value
              label
            }
          }
          services {
            header {
              supheading
              heading
              anchorText
            }
            services {
              heading
              media {
                media {
                  url 
                }
              }
              body {
                richText
              }
              link {
                label
                path
              }
            }
          }
          logos {
            header {
              supheading
              heading
              anchorText
            }
            affix
          }
          testimonials {
            title
            anchorText
            testimonials {
              quote
              position
              name
              company
              description
              media {
                url
                ext
                mime
                alternativeText
              }
              logo {
                url
                alternativeText
                mime
                ext
              }
            }
          }
        }
        headers(where: {language: $language}) {
          ...SiteHeader
        }
        footers(where: { language: $language }) {
          ...SiteFooter
        }
      }
  }
`

export default About
